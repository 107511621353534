import * as React from 'react'
import { useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const SayHelloMushroomRoot = () => {

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const moElement = document.querySelector('.say-hello__mo');

    gsap.to(moElement, {
      rotation: 180, // Rotate 360 degrees
      scrollTrigger: {
        id: "moElementTrigger",
        trigger: moElement,
        start: "top bottom", // When the top of moElement hits the bottom of the viewport
        end: "bottom top", // When the bottom of moElement hits the top of the viewport
        scrub: true
      }
    });

    // Reset all ScrollTriggers when component unmounts
    return () => {

      // We kill all scroll triggers so the next pages can recreate them.
      //ScrollTrigger.getAll().forEach(trigger => trigger.kill())

    };
  }, []);

  const Bubble = ({ children }) => (
    <div className="say-hello-bubble rounded-4 bg-dark text-white p-1 p-xl-2">
      <div className="say-hello-bubble__circle position-absolute"></div>
      <div className="say-hello-bubble__line position-absolute"></div>
      {children}
    </div>
  );

  return (
    <section id='sayHello' className='say-hello pt-2 pt-sm-3 pt-xl-4 pb-1'>
      <Container className="container-narrow">
        <h2 className="text-dark font-swish fw-bolder position-relative mb-1 mb-sm-2 mx-1 mx-sm-2 mx-lg-0 z-1 position-relative" data-text="Say hello to MushroomRoot">Say hello to MushroomRoot</h2>
        <div className='pt-sm-1 d-flex flex-column z-0 position-relative'>
          <Bubble>
            <b className='font-swish fw-bold ff-serif'>MushroomRoot™</b> is meati's special type of mycelium, a big bundle of superfine fungi filaments that looks a bit like cotton candy.
          </Bubble>
          <div className='position-relative'>
            <div className="say-hello__mo">
              <StaticImage
                src="../../images/mo/mo-yum-orange.png"
                alt="Meati Mo Yum Face"
                layout="constrained"
              />
            </div>
            <StaticImage
              src="../../images/mo/mo-yum-orange-face.png"
              placeholder='blurred'
              alt="Meati Mo Yum Face"
              layout="constrained"
              className="say-hello__mo-face position-absolute top-0 start-0 h-100 w-100"
            />
          </div>
          <Bubble>
            Our <b className='font-swish fw-bold ff-serif'>MushroomRoot™</b> (<i>Neurospora crassa</i> mycelium) is special because it's a complete-protein whole food straight from Mother Nature that inherently makes possible everything people want in their meals: incredible texture, undeniable deliciousness, amazing nutrition, and planet-friendliness. <Link to="/mushroom-root" className='text-warning'>Learn more →</Link>
          </Bubble>
        </div>
      </Container>
    </section>
  )
}

export default SayHelloMushroomRoot
