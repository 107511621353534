import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useCart } from '@shopify/hydrogen-react'
import _ from 'lodash'
import EventEmitter from '../utilities/event-emitter'
import GTMPush from '../utilities/gtm-push'
import { LocalCartContext } from '../context/cart-context'
import useBetterMediaQuery from '../hooks/use-better-media-query'

export const HydroAddToCart = ({
  variantId,
  sellingPlanId,
  available,
  title,
  price,
  handle,
  dataAdText = 'add to cart',
  btnVariant,
  buttonText = 'Add to Cart',
  withControls = false,
  callback,
  ...props
}) => {
  const isMobile = useBetterMediaQuery('(max-width:1199px)')

  const { linesAdd, status, lines, linesUpdate } = useCart()
  const [count, setCount] = useState(0)
  const [countDirection, setCounDirection] = useState('up')
  const { cartItems, isCartTypeSubscribe } = useContext(LocalCartContext)

  const addElement = () => {
    if (sellingPlanId) {
      linesAdd({
        merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
        sellingPlanId: `gid://shopify/SellingPlan/${sellingPlanId}`,
        quantity: 1,
      })
    } else {
      linesAdd({
        merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
        quantity: 1,
      })
    }
    cartItems.push(`gid://shopify/ProductVariant/${variantId}`)
  }

  const addToCart = e => {
    e.preventDefault()
    e.stopPropagation()
    if (callback) {
      callback()
    }

    GTMPush(
      {
        event: 'add_to_cart',
        adText: isCartTypeSubscribe ? 'Subscribe' : 'one_time_purchase',
        ecommerce: {
          currency: 'USD',
          value: price,
          items: [
            {
              item_name: title,
              item_id: variantId,
              price,
              item_brand: 'Meati Foods',
              quantity: 1,
            },
          ],
        },
      },
      true
    )

    const kItem = {
      Name: title,
      ProductID: variantId,
      URL: `https://meati.com/products/${handle}`,
      Brand: 'Meati Foods',
      Price: price,
    }

    if (window.klaviyo) {
      window.klaviyo.push(['track', 'Added to Cart', kItem])
    }
    setCount(prevCount => prevCount + 1)
    setCounDirection('up')
    addElement()
    isMobile && EventEmitter.emit('OpenMiniCart', {})
  }

  const reduceByOne = e => {
    e.preventDefault()
    if (count > 0) {
      setCount(prevCount => prevCount - 1)
      setCounDirection('down')
    }
  }

  const updateElement = direction => {
    const item = _.first(_.filter(lines, { merchandise: { id: `gid://shopify/ProductVariant/${variantId}` } }))
    if (sellingPlanId) {
      linesUpdate([
        {
          id: item.id,
          merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
          sellingPlanId: `gid://shopify/SellingPlan/${sellingPlanId}`,
          quantity: item.quantity + (direction === 'up' ? 1 : -1),
        },
      ])
    } else {
      linesUpdate([
        {
          id: item.id,
          quantity: item.quantity + (direction === 'up' ? 1 : -1),
        },
      ])
    }
  }

  const deleteElement = () => {
    const item = _.first(_.filter(lines, { merchandise: { id: `gid://shopify/ProductVariant/${variantId}` } }))
    linesUpdate([
      {
        id: item.id,
        merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
        quantity: 0,
      },
    ])
  }

  useEffect(() => {
    const item = _.first(_.filter(lines, { merchandise: { id: `gid://shopify/ProductVariant/${variantId}` } }))
    if (lines.length && item !== undefined) {
      setCount(item.quantity)
    } else {
      setCount(0)
    }
  }, [lines])

  return (
    <>
      {withControls && count > 0 && (
        <button type="button" onClick={reduceByOne} disabled={status === 'updating'}>
          -
        </button>
      )}
      {withControls && count > 0 ? (
        <span>{count}</span>
      ) : (
        <button
          type="button"
          className={`btn btn-${btnVariant} btn-block w-100 btn-lg ad-el-tracked`}
          onClick={addToCart}
        >
          {buttonText}
        </button>
      )}

      {withControls && count > 0 && (
        <button type="button" onClick={addToCart} disabled={status === 'updating'}>
          +
        </button>
      )}
    </>
  )
}
