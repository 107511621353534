import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import UniversalForm from '../common/form-universal'
import ComeBackSoon from '../../images/marketplace/stickers/come-back-soon.svg'
import Hot from '../../images/marketplace/stickers/hot.svg'

const CheckBack = () => {

  return (
    <section className="innovation py-2 py-sm-3 py-md-4 py-lg-5 py-xl-6">
      <Container fluid="lg">
        <div className="bg-dark rounded-4 ff-serif p-2 px-lg-4 pt-xl-4 mx-1 mx-md-3 mx-xl-4 position-relative">
          <h2 className="fw-bolder text-white mb-2 mb-xl-3">
            Check back here for more <span className="text-warning">fresh cuts!</span>
          </h2>
          <div className='position-relative'>
            <StaticImage
              src="../../images/marketplace/package-yellow.jpg"
              alt="Meati innovation in brown kraft packaging"
              layout="constrained"
              className='rounded-4 mb-2'
              imgClassName='rounded-4'
              placeholder="blurred"
              width={1040}
            />
            <img src={Hot} className='marketplace-sticker marketplace-sticker--hot marketplace-animation--rotate-in' alt="Hot Sticker" />
          </div>
          <Row className='align-items-center'>
            <Col lg="6">
              <h3 className='text-warning fs-1'>Coming soon</h3>
              <p className='text-white fs-5 mb-lg-0'>Be the first to hear when our chefs cook up something new.</p>
            </Col>
            <Col lg="6">
              <div className='bg-white rounded-4 ff-serif p-1'>
                <UniversalForm />
              </div>
            </Col>
          </Row>
          <img src={ComeBackSoon} className='marketplace-sticker marketplace-sticker--come-back-soon marketplace-animation--flip-in' alt="Come Back Soon Sticker" />
        </div>
      </Container>
    </section>
  )
}

export default CheckBack
