import { useStaticQuery, graphql } from 'gatsby'

export const useHolidayData = () => {
  const products = useStaticQuery(graphql`
    query {
      items: allShopifyProduct(
        filter: { tags: { in: "holiday bundles" } }
        sort: { fields: metafields___product___title, order: DESC }
      ) {
        edges {
          node {
            title
            images {
              gatsbyImageData(width: 602, height: 602, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
              altText
              originalSrc
            }
            storefrontId
            shopifyId
            legacyResourceId
            description
            descriptionHtml
            id
            handle
            priceRangeV2 {
              minVariantPrice {
                amount
              }
            }
            variants {
              availableForSale
              legacyResourceId
              storefrontId
              title
              price
              id
              selectedOptions {
                name
                value
              }
            }
            metafields {
              type
              key
              value
            }
          }
        }
      }
    }
  `)

  return products
}
