import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const jerkytrioNF = [
  {
    title: 'Original Jerky',
    ingredients:
      'MushroomRoot™ (mycelium), Sugar, Brown Sugar, Salt, Less Than 2% Of: Natural Flavor, Yeast Extract, Garlic Powder, Acacia Gum, Oat Fiber, Chickpea Flour',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-original.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Peppered Jerky',
    ingredients:
      'MushroomRoot™ (mycelium), Sugar, Sea Salt, Black Pepper, Less Than 2% Of: Natural Flavor, Acacia Gum, Dehydrated Garlic, Oat Fiber, Chickpea Flour',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-original.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Sweet Chile Jerky',
    ingredients:
      'MushroomRoot™ (mycelium), Brown Sugar, Dehydrated Garlic, Apple Cider Vinegar Powder, Less Than 2% Of: Salt, Natural Flavor, Acacia Gum, Spices (Red Pepper, Coriander), Oat Fiber, Chickpea Flour',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-sweet-chile.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]
