import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button, Form } from 'react-bootstrap';

const BISForm = (props) => {
  const {
    variantID, productID, title, btnVariant,
  } = props;

  const [validated, setValidated] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const processForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    setFormDisabled(true);

    fetch('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: new URLSearchParams({
        a: process.env.GATSBY_KLAVIYO_COMPANY_ID,
        email: emailValue,
        platform: 'shopify',
        variant: variantID,
        product: productID,
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <h5 className="mb-1 text-warning">
        {title || "Aw dang! Looks like we're sold out. Stay tuned for our next drop!"}
      </h5>
      <Form
        noValidate
        validated={validated}
        onSubmit={processForm}
        data-ad-element-type="leadform in stock"
        data-ad-text="Subscribe to receive updates when this item is in stock"
        data-ad-button-location="body"
        id="backInStockForm"
      >
        <Row className="d-flex flex-nowrap gap-1 g-0">
          <Col className="col-sm-8">
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                className="form-control-lg"
                required
                type="email"
                placeholder="Email address"
                value={emailValue}
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="col-sm-4">
            <Button
              variant={btnVariant || 'primary'}
              className="btn-lg"
              type="submit"
              disabled={formDisabled}
              data-ad-element-type="leadform submit"
              data-ad-text="Submit"
              data-ad-button-location="leadform in stock"
              data-ad-button-id="version 1"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <Alert variant="success" className="mt-1" show={showAlert}>
        Thanks! We'll be in touch.
      </Alert>
    </div>
  );
}

export default BISForm;