import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { jerkytrioNF } from './jerkytrio-nf'

export const freshfiveNF = [
  {
    title: 'Garlic Pepper Steaks',
    ingredients:
      'MushroomRoot™ (Mycelium), Spices (Black Pepper, Allspice, Coriander), Dehydrated Garlic, Sea Salt, Dehydrated Onion, Less Than 2% Of: Natural Flavor, Fruit Juice For Color, Oat Fiber, Vegetable Juice for Color, Lycopene for Color.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-garlic-pepper.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Italian Seasoned Cutlets',
    ingredients:
      'MushroomRoot™ (Mycelium), Less Than 2% Of: Spices, Dehydrated Bell Pepper, Dehydrated Onion, Dehydrated Garlic, Brown Sugar, Mustard, Yeast Extract, Salt, Natural Flavor, Acacia Gum, Oat Fiber, Chickpea Flour.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-italian.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Spicy Crispy Cutlets',
    ingredients:
      'MushroomRoot™ (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Spices, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicarbonate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Dextrose, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Turmeric Extract, Annatto Extract, Potato Protein, Sunflower Oil, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-spicy-crispy.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Bites',
    ingredients:
      'MushroomRoot (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicaronate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Spices, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Potato Protein, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-crispy-bites.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  ...jerkytrioNF,
]
