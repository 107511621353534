import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const jerkyTrioItems = [
  {
    title: 'Original Jerky',
    description: 'Sweet-forward OMG savoriness with undertones of onion and garlic',
    image: (
      <StaticImage
        src="../images/marketplace/jerky-original.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Peppered Jerky',
    description: 'A savory punch of rambunctious black pepper that keeps the tongue tingling',
    image: (
      <StaticImage
        src="../images/marketplace/jerky-peppered.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Sweet Chile Jerky',
    description: 'A sweet-heat medley of red chile and black pepper with flourishes of onion and garlic',
    image: (
      <StaticImage
        src="../images/marketplace/jerky-sweet-chile.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]

export const merryMixerData = [
  {
    title: 'Classic Steaks',
    qty: '2 Cuts',
    description:
      'Our made-from-MushroomRoot™ star of the plate that is ready to sear and show off your secret marinade',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/box-classic-steaks_720.png"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Carne Asada Steaks',
    qty: '2 Cuts',
    description:
      'Our made-from-MushroomRoot™, gently spicy star of the plate you can throw on the grill and enjoy on its own or as a protein base',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/box-carne-asada_720.png"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Garlic Pepper Steaks',
    qty: '2 Cuts',
    description: 'Protein-packed Classic Steak punched up with pepper and garlic',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/garlic-steaks_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Classic Cutlets',
    qty: '2 Cuts',
    description: 'Our made-from-MushroomRoot™ juicy essential that goes with just about whatever you’re cooking',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/box-classic-cutlets_720.png"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Cutlets',
    qty: '2 Cuts',
    description: 'Our made-from-MushroomRoot™ version of the can-you-hear-that-crunch crispy chicken cutlet',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/box-crispy-cutlets_720.png"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Italian Seasoned Cutlets',
    qty: '2 Cuts',
    description: 'Juicy and tender Classic Cutlet brushed with sunny herbs and spices',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/italian-cutlets_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Spicy Crispy Cutlets',
    qty: '2 Cuts',
    description: 'Comfort food with a tongue-tingling crunch and nutrient punch',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/spicy-cutlets_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Bites',
    qty: '1 lb',
    description: 'Savory and crunchy nibble-sized nuggets packed with nutrients and Good Energy®',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/crispy-bites-1_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]

export const freshFiveData = [
  {
    title: 'Garlic Pepper Steaks',
    qty: '4 Cuts',
    description: 'Protein-packed Classic Steak punched up with pepper and garlic',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/garlic-steaks_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
    ingredients:
      'MushroomRoot™ (Mycelium), Spices (Black Pepper, Allspice, Coriander), Dehydrated Garlic, Sea Salt, Dehydrated Onion, Less Than 2% Of: Natural Flavor, Fruit Juice For Color, Oat Fiber, Vegetable Juice for Color, Lycopene for Color.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-garlic-pepper.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Italian Seasoned Cutlets',
    qty: '4 Cuts',
    description: 'Juicy and tender Classic Cutlet brushed with sunny herbs and spices',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/italian-cutlets_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
    ingredients:
      'MushroomRoot™ (Mycelium), Less Than 2% Of: Spices, Dehydrated Bell Pepper, Dehydrated Onion, Dehydrated Garlic, Brown Sugar, Mustard, Yeast Extract, Salt, Natural Flavor, Acacia Gum, Oat Fiber, Chickpea Flour.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-italian.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Spicy Crispy Cutlets',
    qty: '4 Cuts',
    description: 'Comfort food with a tongue-tingling crunch and nutrient punch',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/spicy-cutlets_720.jpg"
        alt="pouch of Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
    ingredients:
      'MushroomRoot™ (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Spices, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicarbonate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Dextrose, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Turmeric Extract, Annatto Extract, Potato Protein, Sunflower Oil, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-spicy-crispy.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Bites',
    qty: '1 lb',
    description: 'Savory and crunchy nibble-sized nuggets packed with nutrients and Good Energy®',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/crispy-bites-1_720.jpg"
        alt="1 lb Crispy Bites"
        layout="constrained"
        placeholder="blurred"
      />
    ),
    ingredients:
      'MushroomRoot (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicaronate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Spices, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Potato Protein, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-crispy-bites.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Jerky Trio',
    description: '3 packs of Good Energy on the go: Original, Sweet Chile, Peppered',
    image: (
      <StaticImage
        src="../images/marketplace/merrymixer/jerky-trio-sq_720.jpg"
        alt="Meati jerky"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]
