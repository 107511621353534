import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const merrymixerNF = [
  {
    title: 'Classic Steak',
    ingredients:
      'Mushroom Root (mycelium), Less Than 2% Of: Salt, Natural Flavor, Fruit Juice for Color, Oat Fiber, Vegetable Juice for Color, Lycopene for Color. Manufactured & distributed by Emergy Inc. DBA Meati Foods, Boulder, CO 80301. Avoid if sensitive to Fungi such as Mushroom, Mold, or Yeast.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-classic-steak.jpg"
        alt="Meati Classic Steak NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Carne Asada Steaks',
    ingredients:
      'Meati Steak (Mushroom Root [mycelium], Less Than 2% Of: Salt, Natural Flavor, Fruit Juice for Color, Oat Fiber, Vegetable Juice for Color, Lycopene for Color), Seasoning Blend (Dehydrated Garlic, Salt, Sugar, Spices, Paprika, Dehydrated Onion, Cilantro, Olive Oil, Natural Flavors, Extractives of Paprika). Manufactured & distributed by Emergy Inc. DBA Meati Foods, Boulder, CO 80301. Avoid if sensitive to Fungi such as Mushroom, Mold, or Yeast.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-carne-asada.jpg"
        alt="Meati Carne Asada NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Garlic & Pepper Steaks',
    ingredients:
      'MushroomRoot™ (Mycelium), Spices (Black Pepper, Allspice, Coriander), Dehydrated Garlic, Sea Salt, Dehydrated Onion, Less Than 2% Of: Natural Flavor, Fruit Juice For Color, Oat Fiber, Vegetable Juice for Color, Lycopene for Color.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-garlic-pepper.jpg"
        alt="Garlic Pepper Steak NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Classic Cutlet',
    ingredients:
      'Mushroom Root (mycelium), Less Than 2% Of: Salt, Natural Flavor, Acacia Gum, Oat Fiber, Chickpea Flour. Manufactured & distributed by Emergy Inc. DBA Meati Foods, Boulder, CO 80301. Avoid if sensitive to Fungi such as Mushroom, Mold, or Yeast. ',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-classic-cutlet.jpg"
        alt="Classic Cutlet NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Cutlet',
    ingredients:
      'Meati Cutlet (Mushroom Root [mycelium], Less Than 2% Of: Salt, Acacia Gum, Natural Flavor, Chickpea Flour, Oat Fiber), Breading (Rice Flour, Canola Oil, Yellow Corn Flour, Potato Protein, Salt, Leavening [Baking Soda, Disodium Diphosphate, Calcium Phosphate], Spice, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Xanthan Gum). Manufactured & distributed by Emergy Inc. DBA Meati Foods, Boulder, CO 80301. Avoid if sensitive to Fungi such as Mushroom, Mold, or Yeast.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-crispy-cutlet.jpg"
        alt="Crispy Cutlet NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Italian Seasoned Cutlets',
    ingredients:
      'MushroomRoot™ (Mycelium), Less Than 2% Of: Spices, Dehydrated Bell Pepper, Dehydrated Onion, Dehydrated Garlic, Brown Sugar, Mustard, Yeast Extract, Salt, Natural Flavor, Acacia Gum, Oat Fiber, Chickpea Flour.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-italian.jpg"
        alt="Italian Seasoned Cutlets NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Spicy Crispy Cutlets',
    ingredients:
      'MushroomRoot™ (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Spices, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicarbonate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Dextrose, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Turmeric Extract, Annatto Extract, Potato Protein, Sunflower Oil, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-spicy-crispy.jpg"
        alt="Spicy Crispy Cutlets NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'Crispy Bites',
    ingredients:
      'MushroomRoot (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicaronate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Spices, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Potato Protein, Xanthan Gum.',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-crispy-bites.jpg"
        alt="Meati Original Jerky NFP"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]
