import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col, Accordion } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import Modal from '@mui/material/Modal'
import { CartLineProvider } from '@shopify/hydrogen-react'
import NutritionIngredientsList from './nutrition-ingredients-list'
import NutritionPanel from './nutrition-panel'
import { HydroAddToCart } from './hydro-add-to-cart'
import BISForm from './bis-form'
import SubscriptionToggle from './shop-subscription-toggle'
import CartVariantToggler from './cart-variant-toggler'
import { jerkyTrioItems, merryMixerData, freshFiveData } from '../../data/product-info'
import { jerkytrioNF } from '../../data/jerkytrio-nf'
import { freshfiveNF } from '../../data/freshfive-nf'
import { crispybitesNF } from '../../data/crispybites-nf'
import { merrymixerNF } from '../../data/merrymixer-nf'

const ProductModal = ({
  open,
  handleClose,
  node,
  images,
  productMeta,
  sellingPlans,
  description,
  availableForSale,
  variant,
  variantId,
  sellingPlanId,
  title,
  meta,
  serverProduct,
  displaySubscriptionOptions = true,
  displayShipsInfo = false,
}) => {
  const cuts = useStaticQuery(graphql`
    query ShopCuts {
      allWpCut {
        nodes {
          slug
          nutritionFacts {
            overview {
              calories
              fieldGroupName
              ingredientsList
              servingSize
              servingsPerContainer
            }
            panelBottom {
              amount
              item
              percentDailyValue
            }
            panelTop {
              amount
              item
              percentDailyValue
            }
          }
        }
      }
    }
  `)
  let matchingCutNode
  const [subscribeAndSave, setSubscribeAndSave] = useState(true)
  const [variantid, setVariantid] = useState(serverProduct?.variants.nodes[0].id.split('/').pop() || null)
  const [cardPrice, setCardPrice] = useState(variant.price)
  const [sellingplanid, setSellingplanid] = useState(null)
  const [nfElements, setNfElements] = useState([])
  const [setByToggler, setSetByToggler] = useState(false)

  if (node && node.node && node.node.handle) {
    matchingCutNode = cuts.allWpCut.nodes.find(cutNode => cutNode.slug === node.node.handle)
    // console.log("matchingCutNode", matchingCutNode);
  }

  useEffect(() => {
    let spid = null
    if (sellingPlans) {
      const spConverted = JSON.parse(sellingPlans)
      spid = spConverted.find(function (o) {
        return Number(o.variant_id) === Number(variantid)
      })
    }
    setSellingplanid(spid?.sellingPlan_id || null)
    setVariantid(spid?.variant_id || null)
  })

  useEffect(() => {
    const nfMapping = {
      'Jerky Trio': jerkytrioNF,
      'The Fresh Five': freshfiveNF,
      'Mission NEWtrition': freshfiveNF,
      'Crispy Bites': crispybitesNF,
      'The Merry Mixer': merrymixerNF,
      'Goal Getter': merrymixerNF,
    }
    const singleProducts = ['Italian Seasoned Cutlets', 'Spicy Crispy Cutlets', 'Garlic & Pepper Steaks'];
    if (singleProducts.includes(title)) {
      const productNFP = merrymixerNF.find(product => product.title === title);
      setNfElements(productNFP ? [productNFP] : []);
    } else {
      setNfElements(nfMapping[title] || []);
    }
  }, [open, title])

  const galleryAndAddToCart = (
    <div className="px-2 mt-1 mt-lg-0 mb-2 px-sm-1 mb-sm-0 pe-lg-3">
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} lazyLoad />
      <div className="shop-modal__add mt-1">
        <CartLineProvider line={variant}>
          {availableForSale ? (
            <>
              {serverProduct?.variants && (
                <CartVariantToggler
                  variants={serverProduct?.variants}
                  sellingPlans={sellingPlans}
                  setCardPrice={setCardPrice}
                  setVariantid={setVariantid}
                  setSellingplanid={setSellingplanid}
                  setSetByToggler={setSetByToggler}
                  btnVariant="dark"
                />
              )}
              {displaySubscriptionOptions && (
                <SubscriptionToggle
                  subscribeAndSave={subscribeAndSave}
                  setSubscribeAndSave={setSubscribeAndSave}
                  cardPrice={cardPrice}
                  style="light"
                />
              )}
              <HydroAddToCart
                variantId={setByToggler ? variantid : variantId}
                sellingPlanId={subscribeAndSave ? (sellingplanid !== null ? sellingplanid : sellingPlanId) : null}
                buttonText="Add To Cart"
                callback={handleClose}
                // withControls
                btnVariant="primary"
              />
            </>
          ) : (
            <BISForm />
          )}
        </CartLineProvider>
      </div>
    </div>
  )

  const renderWhatsInside = item => (
    <div key={item.title} className="d-flex align-items-center pb-1 text-danger">
      <div className="rounded-circle overflow-hidden d-flex align-items-center justify-content-center ratio ratio-1x1 w-25 flex-shrink-0 me-1 border border-1 border-light">
        {item.image}
      </div>
      <div>
        <div className="mb-14 pb-14 d-flex align-items-start justify-content-between border-bottom border-light">
          <h5 className="fw-bolder me-1 mb-0">{item.title}</h5>
          {item.qty && <span className="text-nowrap">{item.qty}</span>}
        </div>
        <div className="lh-sm">{item.description}</div>
      </div>
    </div>
  )

  const whatsInsideMap = {
    'The Merry Mixer': {
      data: merryMixerData,
    },
    'Goal Getter': {
      data: merryMixerData,
    },
    'The Fresh Five': {
      data: freshFiveData,
    },
    'Mission NEWtrition': {
      data: freshFiveData,
    },
    'Jerky Trio': {
      data: jerkyTrioItems,
    },
    // Add other products as needed
  }

  return (
    <Modal
      id="shopModal"
      open={open}
      onClose={handleClose}
      aria-labelledby="Product Modal"
      aria-describedby="Product Modal"
      className="d-flex align-items-center shop-modal"
      disableRestoreFocus
    >
      <div className="modal-content mx-auto bg-white d-flex flex-column position-relative">
        <div className="p-1 px-lg-2 bg-light">
          <h1 className="fw-bold h2">{title}</h1>
          <div className="d-flex justify-content-start align-items-center">
            {meta && <div className="h6 mb-0 lh-sm me-1 pe-1 border-end border-danger">{meta} </div>}
            <div className="fw-bold fs-4">${Math.round(variant.price)}</div>
          </div>
        </div>
        <div className="px-1 px-lg-3 flex-grow-1 position-relative overflow-y-scroll overflow-x-hidden">
          <Row className="align-items-center gx-sm-3 gx-xxl-6 h-100">
            <Col sm="6" className="order-2 order-sm-1 py-sm-1 py-lg-3">
              <div className="border-bottom border-primary pb-1 ff-serif">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              <Accordion defaultActiveKey="ingredients" flush id="productModalAccordion">
                {whatsInsideMap[title] && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What's Inside?</Accordion.Header>
                    <Accordion.Body>
                      {whatsInsideMap[title].data.map(renderWhatsInside)}
                      <div className="h6 mb-0 text-center fst-italic">All made from MushroomRoot™!</div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {!!nfElements.length && (
                  <>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Nutrition Details</Accordion.Header>
                      <Accordion.Body>
                        {nfElements.map((item, index) => (
                          <div className="mb-1" key={index}>
                            <h5 className="text-danger">{item.title}</h5>
                            {item.nfp}
                          </div>
                        ))}
                        <div className="h6 mb-0 text-center fst-italic">All made from MushroomRoot™!</div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="ingredients">
                      <Accordion.Header>Ingredients</Accordion.Header>
                      <Accordion.Body>
                        {nfElements.map((item, index) => (
                          <div className="mb-1" key={index}>
                            <h5 className="text-danger">{item.title}</h5>
                            <NutritionIngredientsList ingredients={item.ingredients} />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                {matchingCutNode && (
                  <>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Nutrition Details</Accordion.Header>
                      <Accordion.Body className="px-0 pb-0">
                        <div className={`nfp nfp-${matchingCutNode.slug} mb-1`}>
                          <NutritionPanel node={matchingCutNode} />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="ingredients">
                      <Accordion.Header>Ingredients</Accordion.Header>
                      <Accordion.Body>
                        <NutritionIngredientsList
                          ingredients={matchingCutNode.nutritionFacts.overview.ingredientsList}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
              </Accordion>
            </Col>
            <Col sm="6" className="order-1 order-sm-2 d-sm-none">
              {galleryAndAddToCart}
            </Col>
          </Row>
        </div>
        <div className="d-none d-sm-flex align-items-center position-absolute w-50 h-100 end-0 top-0 overflow-y-scroll pt-6">
          {galleryAndAddToCart}
        </div>

        <button
          className="btn btn-link text-dark opacity-75 position-absolute top-0 end-0"
          onClick={handleClose}
          type="button"
        >
          <FontAwesomeIcon className="fa-2x" icon={faTimes} />
        </button>
      </div>
    </Modal>
  )
}

export default ProductModal
