import React, { useState } from 'react'
import { CartLineProvider } from '@shopify/hydrogen-react'
import _ from 'lodash'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { HydroAddToCart } from './hydro-add-to-cart'
import BISForm from './bis-form'
import SubscriptionToggle from './shop-subscription-toggle'
import Bubble from '../svg-icons/bubble'

const ImageComponent = props => {
  const { products, node } = props

  if (node.node.handle === 'goal-getter') {
    return (
      <StaticImage
        src="../../images/marketplace/goal-getter-cover.jpg"
        quality={95}
        layout="constrained"
        width={1004}
        alt="The Merry Mixer"
        className="rounded-4 mb-1"
        imgClassName="rounded-4"
      />
    )
  }

  if (node.node.handle === 'mission-newtrition') {
    return (
      <StaticImage
        src="../../images/marketplace/mission-newtrition-cover.jpg"
        quality={95}
        layout="constrained"
        width={1004}
        alt="The Fresh Five"
        className="rounded-4 mb-1"
        imgClassName="rounded-4"
      />
    )
  }

  const imgData = getImage(products[node.node.handle.replace(/-/g, '')])
  if (imgData === undefined) {
    return (
      <GatsbyImage
        image={node.node.images[0].gatsbyImageData}
        alt={`Meati™ ${node.node.title}`}
        width={410}
        layout="constrained"
        className="rounded-4 mb-1"
        imgClassName="rounded-4"
      />
    )
  }
  return (
    <div className="bg-light rounded-4 py-1 px-3 mb-1">
      <GatsbyImage image={imgData} alt={`Meati™ ${node.node.title}`} width={410} layout="constrained" />
    </div>
  )
}

const ProductTileSimple = props => {
  const {
    node,
    index,
    products,
    showModal,
    serverProducts,
    displaySubscriptionOptions = true,
    titleOnTop,
    background = 'dark',
    isHoliday = false,
    displayShipsInfo = false
  } = props
  const variantid =
    _.find(node.node.metafields, { key: 'bundle_variantid' })?.value ?? node.node.variants[0].legacyResourceId
  const sellingPlanId = _.find(node.node.metafields, { key: 'bundle_sellingplanid' })?.value

  const [subscribeAndSave, setSubscribeAndSave] = useState(true)

  const serverProduct = _.find(serverProducts, function (product) {
    return product.id === node.node.storefrontId
  })

  const serverVariant = _.find(serverProduct?.variants.nodes, function (variant) {
    return variant.id === `gid://shopify/ProductVariant/${variantid}`
  })

  const badge = _.findLast(node.node.metafields, { key: 'badge' })?.value

  return (
    <div
      className={`rounded-4 bg-${background} text-light d-flex flex-column justify-content-between product-tile position-relative h-100`}
    >
      <div role="button" onClick={showModal} className="p-1">
        {titleOnTop && <h3 className="text-info h2 text-center fw-bold mb-1">{node.node.title}</h3>}
        <div className="position-relative">
          {badge && <Bubble type="primary product-tile__bubble" text={badge} />}
          <ImageComponent products={products} node={node} />
        </div>
        {titleOnTop && (
          <div className="ff-serif px-12 text-dark" dangerouslySetInnerHTML={{ __html: node.node.description }} />
        )}
        {!titleOnTop && (
          <div className="d-flex justify-content-between align-items-start mb-12">
            <h3 className="fw-bolder fs-2">{node.node.title}</h3>
            <div className="fw-bold fs-4 text-nowrap">{node.node.variants[0].title}</div>
          </div>
        )}
      </div>
      <div className="p-1">
        {displaySubscriptionOptions ? (
          <SubscriptionToggle
            subscribeAndSave={subscribeAndSave}
            setSubscribeAndSave={setSubscribeAndSave}
            cardPrice={Math.round(node.node.variants[0].price)}
          />
        ) : isHoliday ? (
          <div className="d-flex justify-content-between text-dark px-11 mb-1">
            <div>
              <div className="fs-5">Shipping Included!</div>
              { displayShipsInfo && <div className="fs-5">{ displayShipsInfo }</div> }
            </div>
            <div className="fw-bold fs-5">${Math.round(node.node.variants[0].price)}</div>
          </div>
        ) : (
          <div className="fw-bold fs-5 text-end mb-1">${Math.round(node.node.variants[0].price)}</div>
        )}
        {serverVariant?.availableForSale ? (
          <CartLineProvider line={node.node.variants[0]}>
            <HydroAddToCart
              variantId={variantid}
              sellingPlanId={subscribeAndSave ? sellingPlanId : null}
              buttonText="Add To Cart"
              price={node.node.variants[0].price}
              title={node.node.title}
              // withControls
              btnVariant="primary"
            />
          </CartLineProvider>
        ) : (
          <BISForm />
        )}
      </div>
    </div>
  )
}

export default ProductTileSimple
