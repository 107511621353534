'use client'

import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { gsap } from 'gsap'
import { StaticImage } from 'gatsby-plugin-image'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import queryString from 'query-string'
import { useGiftMode } from '../components/context/gift-mode-context'
import Layout from '../components/layout'
import Seo from '../components/seo'
import MeatiMarketplaceLogo from '../images/marketplace/meati-marketplace-logo.svg'
import FAQMarketplace from '../components/common/faq-marketplace'
import SayHelloMushroomRoot from '../components/sections/say-hello-mushroom-root'
import CheckBack from '../components/sections/shop-check-back'
import StickerScrollTrigger from '../components/utilities/sticker-scrolltrigger'
import Products from '../components/sections/products'
import Cart from '../components/common/cart'
import useBetterMediaQuery from '../components/hooks/use-better-media-query'
import client from '../client/shopify-client'
import FeaturedProducts from '../components/sections/featured-products'
import HolidayProducts from '../components/sections/holiday-products'
import GiftRibbon from '../components/cart/gift-ribbon'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

const ShopPage = ({ location }) => {
  return (
    <Layout className="marketplace">
      <PageContentComponent location={location} />
    </Layout>
  )
}

const PageContentComponent = ({ location }) => {
  const isDesktop = useBetterMediaQuery('(min-width: 1200px)')
  const [products, setProducts] = useState([])
  const { giftMode, toggleGiftMode } = useGiftMode()
  const { mode } = queryString.parse(location.search)

  const GRAPHQL_QUERY = `
  query {
    products(first: 40) {
      nodes {
        id
        title
        handle
        variants(first: 2) {
          nodes {
            availableForSale
            id
            title
            product {
              availableForSale
              description
              title
            }
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

  const getServerSideProps = async () => {
    // Get the Storefront API url
    const response = await fetch(client.getStorefrontApiUrl(), {
      body: JSON.stringify({
        query: GRAPHQL_QUERY,
      }),
      // Generate the headers using the private token. Additionally, you can pass in the buyer's IP address from the request object to help prevent bad actors from overloading your store.
      headers: client.getPublicTokenHeaders({ buyerIp: '...' }),
      method: 'POST',
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    const json = await response.json()
    return { props: json }
  }

  useEffect(() => {
    getServerSideProps().then(({ props }) => {
      setProducts(props.data.products.nodes)
    })
    if (mode === 'gift') {
      toggleGiftMode(true)
    }
  }, [])

  useEffect(() => {
    gsap.to(window, { duration: 0.35, scrollTo: 0 })

    let scrollTriggerRefreshTimeout

    if (isDesktop) {
      // scrolltrigger for sticky cart
      ScrollTrigger.create({
        trigger: '#stickyCart',
        start: `top top+=100px`,
        end: 'bottom bottom',
        endTrigger: '#productTiles',
        pin: true,
        pinSpacing: false,
      })

      // Set a timer to refresh the ScrollTrigger after 5 seconds
      scrollTriggerRefreshTimeout = setTimeout(() => {
        ScrollTrigger.refresh()
      }, 5000)
    }

    return () => {
      // Clear the timeout when the component unmounts or if the effect re-runs
      clearTimeout(scrollTriggerRefreshTimeout)

      // We kill all scroll triggers so the next pages can recreate them.
      ScrollTrigger.getAll().forEach(trigger => trigger.kill())
    }
  }, [isDesktop])

  return (
    <>
      <StickerScrollTrigger />
      <Seo title="Shop" />
      <header className="marketplace__header position-relative">
        {giftMode && <GiftRibbon />}
        <div className="checkers" />
        <Row className="gx-0">
          <Col xl="9">
            <Container className="container--shop">
              <Row className="align-items-center py-1 py-sm-2 pt-xxl-4 gx-2">
                <Col sm="7">
                  <div className="marketplace__header__content p-2 pt-1 px-sm-1">
                    <h1 className="text-center mb-2 mb-lg-3">
                      <span className="visually-hidden">Meati Marketplace</span>
                      <img src={MeatiMarketplaceLogo} alt="Meati Marketplace Logo" />
                    </h1>
                    <div className="subtitle h4">
                      Your favorite meati cuts and all new MushroomRoot-powered delectables you can't find anywhere
                      else.
                    </div>
                    <div onClick={() => toggleGiftMode()} role="button" className="stack-parent mt-2">
                      <StaticImage
                        src="../images/marketplace/gift-mode-button.jpg"
                        quality={95}
                        placeholder="blurred"
                        alt="Discount"
                        layout="constrained"
                        className="rounded-4 stack-child"
                        imgClassName="rounded-4"
                      />
                      <div className="stack-child py-1 px-3 d-flex justify-content-end align-items-center fs-4 text-white lh-1">
                        <div style={{ width: '70%' }}>
                          {giftMode ? (
                            <>
                              Gift mode activated.
                              <br />
                              Fill your cart to get giving!
                            </>
                          ) : (
                            <>
                              Buying presents?
                              <br />
                              Activate gift mode!
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="5">
                  <AnchorLink to="#featuredJerky">
                    <StaticImage
                      src="../images/marketplace/shipping-message.jpg"
                      quality={95}
                      placeholder="blurred"
                      alt="Discount"
                      layout="fullWidth"
                      className="rounded-4"
                      imgClassName="rounded-4"
                    />
                  </AnchorLink>
                </Col>
              </Row>
            </Container>
          </Col>
          {isDesktop && (
            <Col xl="3" className="d-none d-xl-block" style={{ height: 0 }}>
              <div className="rounded-start-4 bg-white" id="stickyCart">
                <Cart />
              </div>
            </Col>
          )}
        </Row>
      </header>
      {products.length > 0 && <FeaturedProducts serverProducts={products} />}
      {products.length > 0 && <HolidayProducts serverProducts={products} />}
      {products.length > 0 && <Products serverProducts={products} />}
      <FAQMarketplace />
      <SayHelloMushroomRoot />
      <CheckBack />
    </>
  )
}

export default ShopPage
