import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const crispybitesNF = [
  {
    title: 'Crispy Bites',
    ingredients:
      'MushroomRoot (Mycelium), Rice Flour, Yellow Corn Flour, Salt, Canola Oil, Less than 2% of: Natural Flavor, Gum Acacia, Oat Fiber, Chickpea Flour, Leavening (Sodium Bicaronate, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Spices, Dried Garlic, Dried Onion, Paprika, Paprika Extract, Potato Protein, Xanthan Gum',
    nfp: (
      <StaticImage
        src="../images/marketplace/nfp-crispy-bites.jpg"
        alt="Meati Crispy Bites"
        layout="constrained"
        placeholder="blurred"
      />
    ),
  },
]
