import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductTiles from '../common/product-tiles'

const Products = props => {
  const { serverProducts } = props
  const products = useStaticQuery(graphql`
    query {
      items: allShopifyProduct(
        filter: { title: { regex: "/^((?!Exclusive).)*$/" }, tags: { in: "cuts" } }
        sort: { fields: metafields___product___title, order: DESC }
      ) {
        edges {
          node {
            title
            images {
              gatsbyImageData(width: 602, height: 602, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
              altText
              originalSrc
            }
            storefrontId
            shopifyId
            legacyResourceId
            description
            descriptionHtml
            id
            handle
            priceRangeV2 {
              minVariantPrice {
                amount
              }
            }
            variants {
              availableForSale
              legacyResourceId
              storefrontId
              title
              price
              id
              selectedOptions {
                name
                value
              }
            }
            metafields {
              type
              key
              value
            }
          }
        }
      }
      classiccutlet: file(relativePath: { eq: "marketplace/boxes-classic-cutlets.png" }) {
        childImageSharp {
          gatsbyImageData(width: 366, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
        }
      }
      classicsteak: file(relativePath: { eq: "marketplace/boxes-classic-steaks.png" }) {
        childImageSharp {
          gatsbyImageData(width: 366, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
        }
      }
      crispycutlet: file(relativePath: { eq: "marketplace/boxes-crispy-cutlets.png" }) {
        childImageSharp {
          gatsbyImageData(width: 366, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
        }
      }
      carneasada: file(relativePath: { eq: "marketplace/boxes-carne-asada.png" }) {
        childImageSharp {
          gatsbyImageData(width: 366, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
        }
      }
      carneasadasteak: file(relativePath: { eq: "marketplace/boxes-carne-asada.png" }) {
        childImageSharp {
          gatsbyImageData(width: 366, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED)
        }
      }
    }
  `)

  const featureBlocks = products.items.edges.map((node, index) => (
    <ProductTiles
      node={node}
      key={index}
      products={products}
      serverProducts={serverProducts}
      displaySubscriptionOptions
      cols={2}
    />
  ))

  return (
    <section className="py-2 py-md-3 innovation" id="productTiles">
      <Row>
        <Col xl="9">
          <Container className="container--shop">
            <div className="bg-white border border-5 border-dark p-1 py-lg-2 rounded-4 mb-1 mb-md-2 text-center">
              <div className="container container-narrow">
                <h2 className="fs-1">Build Your Own Bundle</h2>
                <p className="mb-0 fs-5 lh-sm">Mix and match meati products just the way you like 'em. Eligible for subscriptions.</p>
              </div>
            </div>
            <Row className="g-1">{featureBlocks}</Row>
          </Container>
        </Col>
      </Row>
    </section>
  )
}

export default Products
