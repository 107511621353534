import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const GiftRibbon = () => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  
  const fadeStyle = fade ? { opacity: 0, transition: 'opacity 1s ease' } : {};

  return (
    <div className="position-fixed gift-ribbon z-1 start-0">
      <div className="position-relative rounded-2 border border-primary bg-white p-1 ps-3 shadow-lg z-0 mt-1 ms-4 mt-md-2 ms-md-7 gift-ribbon__message" style={fadeStyle}> 
        <div className="text-primary h5 mb-0">Gift mode activated!</div>
      </div>
      <StaticImage 
        src="../../images/shop/ribbon.png" 
        quality={95} 
        layout="constrained" 
        alt="Ribbon" placeholder='none' 
        className="position-absolute top-0 start-0 z-1"
      />
    </div>
  )
}

export default GiftRibbon