import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrcSet } from 'gatsby-plugin-image'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import ProductModal from './product-modal'
import ProductTileSimple from './product-tile-simple'

const ProductTiles = props => {
  const {
    node,
    index,
    products,
    serverProducts,
    showLearnMore = false,
    displaySubscriptionOptions = true,
    titleOnTop = false,
    background,
    isHoliday = false,
    cols = 2,
  } = props

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const serverProduct = _.find(serverProducts, function (product) {
    return product.id === node.node.storefrontId
  })

  const variantid =
    _.find(node.node.metafields, { key: 'bundle_variantid' })?.value ?? node.node.variants[0].legacyResourceId
  const shipDate = _.find(node.node.metafields, { key: 'shipdate' })?.value

  const sellingPlans = _.find(node.node.metafields, { key: 'skiobundles' })?.value
  let spid = null
  if (sellingPlans) {
    const spConverted = JSON.parse(sellingPlans)
    spid = spConverted.find(function (o) {
      return Number(o.variant_id) === Number(variantid)
    })
  }

  const serverVariant = _.find(serverProduct?.variants.nodes, function (variant) {
    return variant.id === `gid://shopify/ProductVariant/${variantid}`
  })

  const showModal = e => {
    e.preventDefault()
    setOpen(true)
  }

  const images = []

  node.node.images.map(image => {
    const srcSet = getSrcSet(image.gatsbyImageData)
    const original = image.originalSrc
    images.push({
      srcSet,
      original,
    })
  })

  const productMeta = (
    <>
      <h3 className="fw-bold">{node.node.title}</h3>
      <div className="d-flex justify-content-between pb-1">
        <div className="fs-5">
          {node.node.variants[0].title !== 'Default Title' ? (
            <span className="text-uppercase">{node.node.variants[0].title}</span>
          ) : (
            <>
              <span className="text-capitalize d-block">Shipping Included!</span>
              <span className="text-capitalize">Ships week of 12/11</span>
            </>
          )}
        </div>

        <div className="fw-bold fs-4">${Math.round(node.node.variants[0].price)}</div>
      </div>
    </>
  )

  const bundleMessage = (
    <>
      Shipping included!
      {shipDate && <div>{shipDate}</div>}
    </>
  )

  const xlCols = cols === 3 ? 4 : 6

  return (
    <Col sm={6} xl={xlCols} key={`product-${index}`}>
      <ProductTileSimple
        node={node}
        index={index}
        products={products}
        serverProducts={serverProducts}
        showModal={showModal}
        displaySubscriptionOptions={spid}
        titleOnTop={titleOnTop}
        background={background}
        isHoliday={isHoliday}
        displayShipsInfo={shipDate}
      />
      {showLearnMore && (
        <div className="p-1 d-flex justify-content-center">
          <div className="fw-bold text-light h6 mb-0" role="button" onClick={showModal}>
            Product info
            <FontAwesomeIcon icon={faExternalLink} className="ms-12" />
          </div>
        </div>
      )}
      <ProductModal
        open={open}
        handleClose={handleClose}
        node={node}
        images={images}
        productMeta={productMeta}
        sellingPlanId={spid?.sellingPlan_id}
        displayShipsInfo={shipDate}
        availableForSale={serverVariant?.availableForSale}
        variantId={variantid}
        description={node.node.descriptionHtml}
        variant={node.node.variants[0]}
        title={node.node.title}
        displaySubscriptionOptions={spid}
        cost={node.node.variants[0].price}
        meta={node.node.variants[0].title !== 'Default Title' ? node.node.variants[0].title : bundleMessage}
      />
    </Col>
  )
}

export default ProductTiles
