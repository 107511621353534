const CalculateOriginalPrice = (discountPercentage, discountedPrice) => {
  if (
    typeof discountedPrice !== 'number' ||
    typeof discountPercentage !== 'number' ||
    discountPercentage < 0 ||
    discountPercentage > 100
  ) {
    return 'Invalid input. Please provide a valid discounted price and discount percentage.'
  }

  const originalPrice = discountedPrice / (1 - discountPercentage / 100)

  return originalPrice
}

const CalculateDiscountedPrice = (discountPercentage, originalPrice) => {
  if (originalPrice < 0 || discountPercentage < 0 || discountPercentage > 100) {
    return 'Invalid input: Original price must be non-negative, and discount percentage must be between 0 and 100.'
  }

  const discountAmount = (originalPrice * discountPercentage) / 100
  const discountedPrice = originalPrice - discountAmount

  return discountedPrice.toFixed(2)
}

export { CalculateOriginalPrice, CalculateDiscountedPrice }
