import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ProductFeatured from './featured-product'
import { useJerkyData } from '../../hooks/queries/use-product-jerkydata'
import { useCrispyBitesData } from '../../hooks/queries/use-product-crispybites'

const FeaturedProducts = props => {
  const { serverProducts } = props
  const jerky = useJerkyData()
  const crsipyBytes = useCrispyBitesData()

  return (
    <section className="py-1 pb-lg-2 py-xl-3 product-feature">
      <Row>
        <Col xl="9">
          <Container fluid="md" className="container-narrow">
            <div className="px-xl-2 px-xxl-4">
              <h2 className="mb-1 mb-xl-2 text-center fw-bolder">Latest Innovations</h2>
              <Row className="">
                <Col sm="6" className="mb-1 mb-sm-0">
                  <ProductFeatured itemData={crsipyBytes} serverProducts={serverProducts} background="primary" />
                </Col>
                <Col sm="6" id="featuredJerky">
                  <ProductFeatured itemData={jerky} serverProducts={serverProducts} />
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
      </Row>
    </section>
  )
}

export default FeaturedProducts
