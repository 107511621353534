import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import _ from 'lodash'
import { StaticImage, getSrcSet } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { Checkbox } from '@mui/material'
import ProductModal from '../common/product-modal'
import { HydroAddToCart } from '../common/hydro-add-to-cart'
import SubscriptionToggle from '../common/shop-subscription-toggle'
import CartVariantToggler from '../common/cart-variant-toggler'
import Bubble from '../svg-icons/bubble'

const ProductFeatured = ({ itemData, serverProducts, background = 'danger' }) => {
  const { title, variants, descriptionHtml, images, metafields, storefrontId, btnVariant = 'light' } = itemData
  const serverProduct = _.find(serverProducts, function (product) {
    return product.id === storefrontId
  })

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [cardPrice, setCardPrice] = useState(0)
  const [variantid, setVariantid] = useState(serverProduct?.variants.nodes[0].id.split('/').pop() || null)
  const [sellingplanid, setSellingplanid] = useState(null)
  const [subscribeAndSave, setSubscribeAndSave] = useState(true)
  const serverVariant = _.find(serverProduct?.variants.nodes, function (variant) {
    return variant.id === `gid://shopify/ProductVariant/${variantid}`
  })

  const [activeButton, setActiveButton] = useState(0)

  const sellingPlans = _.find(metafields, { key: 'skiobundles' })?.value
  const productSubtitle = _.find(metafields, { key: 'subtitle' })?.value
  const shipDate = _.find(metafields, { key: 'shipdate' })?.value

  let badge = _.findLast(metafields, { key: 'badge' })?.value;

  const showModal = e => {
    e.preventDefault()
    setOpen(true)
  }

  const updateSelection = (e, variant, index) => {
    e.preventDefault()
    setActiveButton(index)
    setVariantid(variant.id.split('/').pop())
    if (subscribeAndSave && sellingPlans !== undefined) {
      const spConverted = JSON.parse(sellingPlans)
      const spid = spConverted.find(function (o) {
        return Number(o.variant_id) === Number(variant.id.split('/').pop())
      })
      setSellingplanid(spid.sellingPlan_id)
    }
    setCardPrice(Math.floor(variant.price.amount))
  }

  useEffect(() => {
    if (sellingPlans !== undefined) {
      const spConverted = JSON.parse(sellingPlans)
      const spid = spConverted.find(function (o) {
        return Number(o.variant_id) === Number(variantid)
      })
      setSellingplanid(spid?.sellingPlan_id)
    }
    setCardPrice(Math.floor(serverVariant?.price.amount))
  }, [])

  const imageArray = []

  images.map(image => {
    const srcSet = getSrcSet(image.gatsbyImageData)
    const original = image.originalSrc
    imageArray.push({
      srcSet,
      original,
    })
  })

  const productMeta = (
    <>
      <h3 className="fw-bold">{title}</h3>
      <div className="d-flex justify-content-between align-items-center pb-1">
        {title === 'Jerky Trio' && <div className="h6 mb-0 me-1">Original, Sweet Chile, Peppered</div>}
        <div className="fw-bold">${Math.round(variants[0].price)}</div>
      </div>
    </>
  )

  return (
    <div className="h-100 d-flex flex-column">
      <div className={`rounded-4 bg-${background} text-white p-1 px-lg-2 product-tile flex-grow-1 d-flex flex-column justify-content-between`}>
        <div>
          <h3 className={`text-${background === 'danger' ? 'warning' : 'white'} font-swish h2 text-center fw-bold mb-1`}>
            {title}
          </h3>
          <div className='position-relative'>
            {badge && <Bubble type="success product-tile__bubble" text={badge} />}
            {title === 'Jerky Trio' ? (
              <StaticImage
                src="../../images/marketplace/jerky-trio.jpg"
                quality={95}
                layout="constrained"
                width={1004}
                alt="Meati™ Jerky Trio"
                className="rounded-4 mb-1"
                imgClassName="rounded-4"
              />
            ) : (
              <StaticImage
                src="../../images/marketplace/crispy-bites-cover.jpg"
                quality={95}
                layout="constrained"
                width={1004}
                alt="Meati™ Jerky Trio"
                className="rounded-4 mb-1"
                imgClassName="rounded-4"
              />
            )}
          </div>
          <div className="d-flex justify-content-between mb-1 px-12 mb-xl-2">
            {productSubtitle ? (
              <div className="ff-serif mb-0 me-1">{productSubtitle}</div>
            ) : (
              <div className="ff-serif" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            )}
          </div>
        </div>
        <div>
          <CartVariantToggler
            variants={serverProduct?.variants}
            sellingPlans={sellingPlans}
            setCardPrice={setCardPrice}
            setVariantid={setVariantid}
            setSellingplanid={setSellingplanid}
          />
          <SubscriptionToggle
            subscribeAndSave={subscribeAndSave}
            setSubscribeAndSave={setSubscribeAndSave}
            cardPrice={cardPrice}
          />
          <HydroAddToCart
            variantId={variantid}
            sellingPlanId={subscribeAndSave ? sellingplanid : null}
            btnVariant={`${background === 'danger' ? 'primary' : 'light'}`}
          />
        </div>
      </div>
      <div className="p-1 d-flex justify-content-center">
        <div className="fw-bold text-primary h6 mb-0" role="button" onClick={showModal}>
          Product info
          <FontAwesomeIcon icon={faExternalLink} className="ms-12" />
        </div>
      </div>
      <ProductModal
        open={open}
        handleClose={handleClose}
        node=""
        description={descriptionHtml}
        images={imageArray}
        productMeta={productMeta}
        displayShipsInfo={shipDate}
        sellingPlanId={subscribeAndSave ? sellingplanid : null}
        variantId={variantid}
        sellingPlans={sellingPlans}
        variant={variants[0]}
        availableForSale={serverVariant?.availableForSale}
        serverProduct={serverProduct}
        // setSellingplanid={setSellingplanid}
        title={title}
        cost={variants[0].price}
        meta={title === 'Jerky Trio' ? 'Original, Sweet Chile, Peppered' : ''}
      />
    </div>
  )
}

export default ProductFeatured
