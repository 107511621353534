import { useStaticQuery, graphql } from 'gatsby'

export const useCrispyBitesData = () => {
  const { item } = useStaticQuery(
    graphql`
      query {
        item: allShopifyProduct(filter: { handle: { eq: "crispy-bites" } }) {
          nodes {
            storefrontId
            legacyResourceId
            title
            descriptionHtml
            handle
            images {
              gatsbyImageData(width: 1920, formats: [WEBP, AUTO])
              altText
              originalSrc
            }
            descriptionHtml
            variants {
              storefrontId
              legacyResourceId
              price
              title
            }
            metafields {
              type
              key
              value
            }
          }
        }
      }
    `
  )

  return item.nodes[0]
}
