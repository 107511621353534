import React, { useState } from 'react'

const CartVariantToggler = ({
  variants,
  sellingPlans,
  setSellingplanid,
  setVariantid,
  setCardPrice,
  setSetByToggler,
  btnVariant = 'light',
}) => {
  const [activeButton, setActiveButton] = useState(0)
  const spConverted = JSON.parse(sellingPlans)

  const updateSelection = (e, variant, index) => {
    e.preventDefault()
    setActiveButton(index)
    if (setSetByToggler) setSetByToggler(true)
    // setVariantid(variant.id.split('/').pop())
    const spid = spConverted.find(o => {
      return Number(o.variant_id) === Number(variant.id.split('/').pop())
    })
    setSellingplanid(spid.sellingPlan_id)
    setVariantid(spid.variant_id)
    if (setCardPrice) setCardPrice(Math.floor(variant.price.amount))
  }

  return (
    <div className="d-flex mb-12">
      {variants.nodes.map((variant, index) => {
        return (
          <button
            type="button"
            key={variant.id}
            disabled={activeButton === index}
            className={`btn btn-${
              activeButton !== index ? 'outline-' : ''
            }${btnVariant} btn-md ad-el-tracked me-14 rounded-4 opacity-100`}
            onClick={e => updateSelection(e, variant, index)}
          >
            {variant.title}
          </button>
        )
      })}
    </div>
  )
}

export default CartVariantToggler
