import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Accordion, Container } from "react-bootstrap"
import AccordionHeader from "../utilities/accordion-header"

const FAQMarketplace = () => {

  const marketplaceFAQ = useStaticQuery(graphql`
    query {
      allWpFaq(
        filter: {fAQCategories: {nodes: {elemMatch: {name: {eq: "Marketplace"}}}}}
        sort: {fields: menuOrder}
      ) {
        nodes {
          title
          content
        }
      }
    }
  `)

  const newFAQItems = marketplaceFAQ.allWpFaq.nodes.map((node, index) =>
    <Accordion.Item key={`child-${index}`} eventKey={`child-${index}`}>
      <AccordionHeader eventKey={`child-${index}`} wrapper={'div'}><div className="h4 mb-0 me-1 fw-bolder">{node.title}</div></AccordionHeader>
      <Accordion.Collapse eventKey={`child-${index}`}>
        <div className='accordion-body' dangerouslySetInnerHTML={{ __html: node.content }} />
      </Accordion.Collapse>
    </Accordion.Item>
  )

  return (
    <section className="py-2 py-md-3 py-xxl-4">
      <Container className="container-narrower">
        <h2 className="fw-bolder text-center mb-lg-2">FAQs</h2>
        <Accordion id="faqAccordion" className="accordion-flush faq-subscriptions">
          {newFAQItems}
        </Accordion>
      </Container>
    </section>
  )

}

export default FAQMarketplace