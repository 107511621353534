import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const StickerScrollTrigger = () => {
  useEffect(() => {

    const stickers = document.querySelectorAll('.marketplace-sticker');

    stickers.forEach(sticker => {
      const baseProps = {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: sticker,
          start: "top 75%",
          toggleActions: "play none none reverse",
        }
      };

      let customProps = {};

      // Apply different animation properties based on the class
      if (sticker.classList.contains('marketplace-animation--flip-in')) {
        customProps = {
          rotationY: -90,
          ease: "elastic.out(1, 0.3)",
          duration: 3
        };
      } else if (sticker.classList.contains('marketplace-animation--rotate-in')) {
        customProps = {
          rotation: -60,
          ease: "bounce.out",
          duration: 1.5
        };
      } else if (sticker.classList.contains('marketplace-animation--from-right')) {
        customProps = {
          x: '200px',
          ease: "back.out(2)",
          duration: 0.5
        };
      }

      // Merge the base properties with the custom properties
      const animationProps = { ...baseProps, ...customProps };

      gsap.from(sticker, animationProps);
    });

    // Cleanup function to kill the ScrollTrigger instances when the component unmounts
    return () => {
      ScrollTrigger.getAll().forEach(st => st.kill());
    };
  }, []);

  return null; // This component doesn't render anything to the DOM
};

export default StickerScrollTrigger;