import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { useHolidayData } from '../../hooks/queries/use-product-holiday'
import ProductTiles from '../common/product-tiles'

const HolidayProducts = props => {
  const { serverProducts } = props
  const holidayData = useHolidayData()

  const holidayBlocks = holidayData.items.edges.map((node, index) => {
    return (
      <ProductTiles
        node={node}
        key={index}
        products={holidayData}
        serverProducts={serverProducts}
        showLearnMore
        displaySubscriptionOptions={false}
        titleOnTop
        background="white"
        isHoliday
      />
    )
  })

  return (
    <section className="holiday-products stack-parent" style={{ zIndex: 0 }}>
      <div className="holiday-products__bg stack-child" />
      <div className="stack-child py-2 py-xl-4 ">
        <Row className="gx-0">
          <Col xl="9">
            <Container xs="9" className="container-narrow">
              <div className="px-xl-2 px-xxl-4">
                <h2 className="fw-bolder text-center">Good Energy Bundles</h2>
                <div className="subtitle text-center mb-lg-2 mb-xl-3 text-light fs-5 lh-sm mb-1 px-xl-5">
                  We mixed and matched Meati products to make it easy to take a Good Energy adventure. Perfect for gifts
                  or treating yourself this holiday season. Not eligible for subscriptions.
                </div>
                <Row className="align-items-center">{holidayBlocks}</Row>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HolidayProducts
