import React from 'react';
import CircleCheckRegular from '../svg-icons/circle-check-regular';
import CircleCheckSolid from '../svg-icons/circle-check-solid';
import { CalculateDiscountedPrice } from '../utilities/calculate-original-price';

const SubscriptionToggle = ({ subscribeAndSave, setSubscribeAndSave, cardPrice, style='dark' }) => {
  const subscribePrice = CalculateDiscountedPrice(10, cardPrice);

  const buttonsConfig = [
    {
      label: 'Subscribe & Save 10%',
      isActive: subscribeAndSave,
      onClick: () => setSubscribeAndSave(true),
      price: `$${subscribePrice}`,
      originalPrice: `$${cardPrice}`
    },
    {
      label: 'One-Time Purchase',
      isActive: !subscribeAndSave,
      onClick: () => setSubscribeAndSave(false),
      price: `$${cardPrice}`
    }
  ];

  return (
    <div className="subscription-toggles">
      {buttonsConfig.map(({ label, isActive, onClick, price, originalPrice }, index) => (
        <div
          key={index}
          className={`d-flex align-items-center justify-content-between p-12 mb-12 rounded subscription-toggle lh-1subscription-toggle--${style} subscription-toggle--${isActive ? 'active' : 'inactive'}`}
          role="button"
          onClick={onClick}
          tabIndex={0}
        >
          <div className="d-flex align-items-center">
            {isActive ? <CircleCheckSolid /> : <CircleCheckRegular />}
            <span className="ms-12 lh-1 d-block">{label}</span>
          </div>
          <div className="price-info ms-1">
            {originalPrice && <s className="me-12">{originalPrice}</s>}
            <strong>{price}</strong>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionToggle;